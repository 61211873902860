@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgb(46, 198, 147);
  background: linear-gradient(90deg, #2ec693 0%, #568e7b 100%);
}

.modal-container {
  width: 80%;
  background-color: #ffffff;
  margin: 15vh 0;
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 0px 20px 7px #77777756;
  padding: 15px;
}

.logo-image {
  min-height: 240px;
  height: 40vh;
}

h1 {
  font-weight: 600;
  margin: 5px 0;
}

h2 {
  font-weight: 500;
  margin: 10px 0;
}

.social-icon-container {
  display: flex;
  gap: 10px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-icon-container svg {
  width: 45px;
  fill: #333;
  transition: 200ms;
  height: auto;
  margin-top: 10px;
}

.social-icon-container svg:hover {
  fill: #568e7b;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .modal-container {
    flex-direction: column;
    width: fit-content;
    gap: 5px;
    padding: 0 0 35px 0;
  }

  .content-container {
    align-items: center;
  }
}
